<template>
  <div
    style="margin-top: 0px"
    class="home"
    @click="removeLists"
  >
    <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
      {{ $t("EmployeeFeedback") }}
    </p>
    <div>
      <div
        class="table__header"
        :style="
          isactive == true
            ? 'justify-content: space-between; margin-bottom: 15px;'
            : 'justify-content: flex-end; margin-bottom: 15px'
        "
      >
        <div
          style="display: flex"
          class="con"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchUser == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchUser != '' && !noSearch"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchUser = ''"
              />
              <input
                v-model="searchUser"
                autocomplete="off"
                type="text"
                name="search-box"
                :placeholder="$t('FullName')"
                :style="
                  getFilteredActiveUsers.length > 0 ||
                    (getFilteredInactiveUsers.length > 0 && noSearch)
                    ? 'border-radius: 8px 8px 0px 0px'
                    : ''
                "
                @keyup="inputChanged"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
              >
            </div>
            <div
              v-if="
                (getFilteredActiveUsers.length > 0 || getFilteredInactiveUsers.length > 0) &&
                  noSearch
              "
              ref="scrollContainer"
              class="dropdrop"
            >
              <div
                v-for="(result, index) in getFilteredActiveUsers.length > 0
                  ? getFilteredActiveUsers
                  : getFilteredInactiveUsers"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter }"
                @click="
                  searchByOn(result);
                  noSearch = false;
                "
              >
                <p style="margin: 0">
                  {{ result.fullName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="width: 100%; padding-top: 16px"
        class="scroll"
      >
        <table
          class="team_table"
          style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); font-size: 12px"
        >
          <thead>
            <tr>
              <th style="padding: 10px; border-radius: 10px 0px 0px 0px; text-align: left;">
                {{ $t("No.") }}
              </th>
              <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                {{ $t("VacationFirstName") }}
              </th>
              <th style="padding: 10px; text-align: left;">
                {{ $t("VacationLastName") }}
              </th>
              <!-- <th style="padding: 10px; text-align: left;">
                {{ $t("VacationCreatedD") }}
              </th> -->
              <!-- <th style="padding: 10px; text-align: left;">
                {{ $t("VacationEmployeeT") }}
              </th> -->
              <th
                v-if="isactive == true"
                style="padding: 10px; text-align: left; cursor: pointer"
                @click="sortByRole"
              >
                {{ $t("VacationRole") }}
                <b-icon-sort-alpha-up-alt v-if="sortedR == false" />
                <b-icon-sort-alpha-down v-else />
              </th>
              <th
                v-else
                style="padding: 10px; text-align: left; cursor: pointer"
                @click="sortByRoleI"
              >
                {{ $t("VacationRole") }}
                <b-icon-sort-alpha-down v-if="sortedRI == false" />
                <b-icon-sort-alpha-up-alt v-else />
              </th>
              <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                {{ $t("Filiale") }}
              </th>
              <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                {{ $t("Rating") }}
              </th>
              <!-- <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                {{ $t("VacationDays") }}
              </th>
              <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                {{ $t("EditVD") }}
              </th>
              <th style="padding: 10px; border-radius: 0px 10px 0px 0px; text-align: left;">
                {{ $t("VacationLeaveReq") }}
              </th> -->
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(user, index) in filteredUsers"
              :key="index"
              v-b-tooltip.hover.bottom
              style="
                border-bottom: 1px solid #dcdfe6;
                color: #606266;
                text-align: left;
                cursor: pointer;
              "
              :title="`Click for ${user.firstName} ${user.lastName} days off details`"
              @click="matProps(user), showSameId(user.userId) , loadFeedbackk(user)"
            >
              <td style="padding: 9px">
                {{ getTotalItemsForUsers - (page - 1) * pageSize - index }}.
              </td>
              <td style="padding: 9px">
                {{ user.firstName }}
              </td>
              <td style="padding: 9px">
                {{ user.lastName }}
              </td>
              <!-- <td style="padding: 9px">
                {{ dateModified(user.created) }}
              </td> -->
              <!-- <td
                v-if="user.employeeType === 'Internal'"
                style="padding: 9px"
              >
                {{ $t("Internal") }}
              </td>
              <td
                v-else
                style="padding: 9px"
              >
                {{ $t("External") }}
              </td> -->
              <td style="padding: 9px">
                {{ user.role }}
              </td>
              <td style="padding: 9px">
                {{ user.storeName }}
              </td>
              <td
                style="display: flex; justify-content: center;"
                @click.stop
              >
                <b-form>
                  <b-form-rating
                    v-model="user.rangingValue"

                    v-b-tooltip.hover.top="$t('Rating') +' '+ nameToShow(user.rangingValue) "
                    variant="warning"
                    class="mb-2"
                    readonly
                  />
                </b-form>
              </td>
              <!-- <td style="padding: 9px">
                {{ user.remainingDays }} / {{ user.totalDays }}
              </td>
              <td style="padding: 9px">
                <b-button
                  v-if="user.storeName != null || user.personRole == 'Manager'"
                  v-b-modal.add-edit-vacation-days
                  style="height:34px; font-size: small; background: linear-gradient(90deg, rgba(38,46,108,1) 0%, rgba(155,42,92,1) 100%, rgba(255,39,79,1) 100%);"
                  @click="saveUser(user), loadRemainingDays(user.userId)"
                >{{ $t("EditVD") }}</b-button>
              </td>
              <td style="padding: 3px">
                <b-button
                  v-if="user.storeName != null || user.personRole == 'Manager'"
                  v-b-modal.leave-request
                  style="height:34px; font-size: small; background: linear-gradient(90deg, rgba(38,46,108,1) 0%, rgba(155,42,92,1) 100%, rgba(255,39,79,1) 100%);"
                  @click="saveUser(user)"
                >{{ $t("VacationLeaveReq") }}</b-button>
              </td> -->
            </tr>
            <div style="margin: 10px;">
              <b-button
                v-if="selectedId != null"
                class="viewAllBtn"
                @click="showAll"
              >
                {{ $t("ViewAll") }}
              </b-button>
            </div>
          </tbody>
        </table>
      </div>
      <div>
        <b-pagination
          v-if="isactive == true"
          v-model="page"
          :total-rows="getTotalItemsForUsers"
          :per-page="pageSize"
          first-number
          last-number
          :hidden="selectedId"
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span>{{ $t("Previous") }}</span>
          </template>
          <template #next-text>
            <span>{{ $t("Next") }}</span>
          </template>
        </b-pagination>
        <b-pagination
          v-else
          v-model="page"
          :total-rows="getTotalItemsForInActiveUsers"
          :per-page="pageSize"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span>{{ $t("Previous") }}</span>
          </template>
          <template #next-text>
            <span>{{ $t("Next") }}</span>
          </template>
        </b-pagination>
      </div>
    </div>
    <!-- @pushFormUser="AddEditVacation" -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';


export default {
  components: {

  },
  props: {
    users: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userObj: {},
      showOtherComponent: false,
      isactive: true,
      searchUser: '',
      noSearch: false,
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      editUser: {
        userId: '',
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        street: '',
        country: '',
        email: '',
        role: '',
        resetPassword: false,
      },
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      employeeId: null,
    };
  },
  computed: {
    ...mapGetters([
      'getEmployeeFeedbackPagination',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForInActiveUsers',
      'getFeedback',
    ]),
    filteredUsers() {
      // filter the users array to only show rows with the same id as the selected id
      return this.selectedId
        ? this.getEmployeeFeedbackPagination.filter((getEmployeeFeedbackPagination) => {
          return getEmployeeFeedbackPagination.userId === this.selectedId;
        })
        : this.getEmployeeFeedbackPagination;
    },
  },
  watch: {
    page(value) {
      if (this.isactive) {
        this.loadEmployeeFeedbackPagination({
          firstName: this.searchUser,
          pageNumber: value,
          pageSize: this.pageSize,
        });
      }
    },
    searchUser(value) {
      this.selectedId = null;
      if (value == '') {
        this.removeLists();
        if (this.isactive) {
          this.loadEmployeeFeedbackPagination({
            firstName: value,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.getInactiveUsersPagination({
            status: false,
            pageNumber: this.page,
            pageSize: this.pageSize,
            fullName: value,
          });
        }
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else if (this.isactive) {
        this.filterActiveUsersByName(value);
      } else {
        this.filterInactiveUsersByName(value);
      }
      this.noSearch = true;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions([
      'loadEmployeeFeedbackPagination',
      'getInactiveUsersPagination',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'filterInactiveUsersByName',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'loadVacationRequests',
      'assignEmployeeToStoree',
      'leaveRequest',
      'remainingDaysOfEmployee',
      'filterActiveUsersByName',
      'loadFeedback',
      'loadEmployeeFeedbackPagination',
    ]),
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false);

        await this.loadEmployeeFeedbackPagination({
          firstName: null,
          lastName: null,
          pageNumber: this.page,
          pageSize: this.pageSize,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    matProps(users) {
      this.$emit('click', users);
      this.classNameCh = users.userId;
      this.$emit('show-the-component');
    },
    loadRemainingDays(value) {
      const currentYear = new Date().getFullYear();
      this.remainingDaysOfEmployee({ employeeUserId: value, year: currentYear });
    },
    async assignEmployeeToFiliale(obj) {
      await this.assignEmployeeToStoree({
        object: obj,
        successCallback: () => {
          this.loadEmployeeFeedbackPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    showSameId(id) {
      // set the selected id to the id of the clicked row
      this.selectedId = id;
    },
    loadFeedbackk(id) {
      // console.log('testt', id.userId)
      const currentYear = new Date().getFullYear();
      this.loadFeedback({ userId: id.userId, year: currentYear })
    },
    showAll() {
      // set the selected id to null to show all rows
      this.selectedId = null;
      this.$emit('show-other-component');
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredActiveUsers.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredActiveUsers[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      // console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    sortByRole() {
      if (this.sortedR == false) {
        this.getEmployeeFeedbackPagination.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedR = true;
      } else {
        this.getEmployeeFeedbackPagination.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedR = false;
      }
    },
    sortByRoleI() {
      if (this.sortedRI == false) {
        this.getInactiveUsers.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedRI = true;
      } else {
        this.getInactiveUsers.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedRI = false;
      }
    },
    nameToShow(value) {
      if (value > 0) {
        return value
      }
      return '0'
    },
    async searchByOn(value) {
      this.searchUser = value.fullName;
      // console.log('123', value.firstName)
      this.noSearch = false;
      await this.loadEmployeeFeedbackPagination({
        firstName: value.firstName,
        lastName: value.lastName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
  },
};
</script>

<style scoped>
.button {
  width: 195px;
}

.viewAllBtn{
  background: transparent linear-gradient(53deg, #0071AE 0%, #55B94A 100%) 0% 0% no-repeat padding-box;
}
.addEmployeeButton {
  width: 210px;
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}
span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
