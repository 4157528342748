<template>
  <div>
    <b-modal
      id="add-employee-review"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('AddReviewFor')} ${user.firstName} ${user.lastName} `"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form>
          <div style="display: flex;">
            <b-form-group
              id="input-group-8"
              :label="$t('EditY')"
              style="width:100%;  margin-right: 10px;"
              label-for="input-25"
            >
              <vue-select
                id="input-5"
                v-model="year"
                required
                disabled
                :options="yearsArray"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
            <b-form-group
              id="input-group-8"
              :label="$t('Month')"
              style="width:100%"
              label-for="input-25"
            >
              <vue-select
                id="input-5"
                v-model="monthh"
                :reduce="(e) => e.value"
                required
                :options="remainingMonths"
                label="name"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </div>
          <b-form-group
            id="input-group-8"
            :label="$t('Rate')"
            style="width:100%"
            label-for="input-25"
          >
            <b-form-rating
              v-model="form.rangingType"
              variant="warning"
              class="mb-2"
              max="4"
            />
          </b-form-group>
          <b-form-group
            id="input-group-8"
            :label="$t('Feedback')"
            style="width:100%"
            label-for="input-25"
          >
            <b-form-textarea
              id="textarea"
              v-model="form.notes"
              placeholder="Write here . . . "
              rows="3"
              max-rows="3"
            />
          </b-form-group>
        </b-form>
      </b-form>
      <div style="margin-top: 10px;">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment'

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['user', 'usedMonths', 'year'],
  data() {
    return {
      yearsArray: [2020, 2021, 2022, 2023],
      rate: null,
      months: [
        {
          value: 1,
          name: 'January',
        },
        {
          value: 2,
          name: 'February',
        },
        {
          value: 3,
          name: 'March',
        },
        {
          value: 4,
          name: 'April',
        },
        {
          value: 5,
          name: 'May',
        },
        {
          value: 6,
          name: 'June',
        },
        {
          value: 7,
          name: 'July',
        },
        {
          value: 8,
          name: 'August',
        },
        {
          value: 9,
          name: 'September',
        },
        {
          value: 10,
          name: 'October',
        },
        {
          value: 11,
          name: 'November',
        },
        {
          value: 12,
          name: 'December',
        },
      ],
      selectedYear: null,
      monthh: null,
      text: null,
      form: {
        userId: this.user.userId,
        year: moment().year(),
        month: moment().month() + 1,
        rangingType: null,
        notes: null,
      },

    };
  },
  computed: {
    ...mapGetters(['getfreeTeamLeaders', 'getVacationRequests']),
    remainingMonths() {
      return this.months.filter((month) => { return !this.usedMonths.includes(month.value) });
    },

  },
  watch: {
    // rate(value) {
    //   if (value == 1) {
    //     this.form.rangingType = 'Bad'
    //   } else if (value == 2 || value == 3) {
    //     this.form.rangingType = 'Good'
    //   } else if (value == 4) {
    //     this.form.rangingType = 'Better'
    //   } else if (value == 5) {
    //     this.form.rangingType = 'Best'
    //   }
    // },
    year(value) {
      this.form.year = value
    },
    'user.userId': {
      handler(value) {
        this.form.userId = value
      },
    },
    user(value) {
      this.form.userId = value.userId
    },
    monthh(value) {
      this.form.month = value
    },
  },
  mounted() {
    this.form.year = this.year
  },
  methods: {
    ...mapActions(['loadUsersFullNamesById', 'cancelVacation']),
    onSubmit() {
      this.$emit('submit', this.form);
      this.$refs.modal.hide()
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.form.notes = '';
      this.rate = 0;
      this.monthh = '';
      this.form.year = moment().year();
      this.form.month = moment().month()
      this.form.rangingType = ''
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  // grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
