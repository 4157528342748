<template>
  <div
    style="margin-top: 0px"
    class="home"
    @click="removeLists"
  >
    <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;margin: 22px 0px;">
      {{ user.firstName + ' ' + user.lastName }}
    </p>
    <div>
      <div
        style="width: 100%; padding-top: 16px"
        class="scroll"
      >
        <div style="display: flex;align-items: baseline;">
          <button
            v-b-modal.add-employee-review
            class="addEmployeeButton button"
            :style="getLanguage == 'en' ? 'border-radius: 6px; width:220px; margin-bottom: 10px' : 'border-radius: 6px; width:250px; margin-bottom: 10px'"
          >
            <b-icon-plus />
            {{ $t("AddReview") }}
          </button>
          <div class="year">
            <button
              style="background: transparent linear-gradient(53deg, #0071AE 0%, #55B94A 100%) 0% 0% no-repeat padding-box;"
              @click="decrementYear"
            >
              <b-icon-arrow-left color="white" />
            </button>
            <h5>{{ year }}</h5>
            <button
              style="background: transparent linear-gradient(53deg, #0071AE 0%, #55B94A 100%) 0% 0% no-repeat padding-box;"
              @click="incrementYear"
            >
              <b-icon-arrow-right color="white" />
            </button>
          </div>
        </div>
        <table
          class="team_table"
          style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); font-size: 12px"
        >
          <thead>
            <tr>
              <th style="padding: 10px; border-radius: 10px 0px 0px 0px; text-align: left;">
                {{ $t("Month") }}
              </th>
              <th style="padding: 10px; text-align: left;">
                {{ $t("Rating") }}
              </th>
              <th style="padding: 10px; text-align: left;">
                {{ $t("Feedback") }}
              </th>
              <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                {{ $t("ExecutorName") }}
              </th>
              <th style="padding: 10px; text-align: left;">
                {{ $t("Actions") }}
              </th>

            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(userr, index) in filtered"
              :key="index"
              style="
                border-bottom: 1px solid #dcdfe6;
                color: #606266;
                text-align: left;
                cursor: pointer;
              "
              @click="matProps(userr)"
            >
              <!-- , loadVacation(userr.userId) -->
              <!-- showSameId(userr.userId), -->
              <td style="padding: 9px">
                {{ monthToString(userr.month) }}
              </td>
              <!-- <td style="padding: 9px">
                {{ userr.rangingType }}
              </td> -->
              <td style="padding: 9px;display: flex; justify-content: center;">
                <b-form>
                  <b-form-rating
                    v-model="userr.rangingType"
                    variant="warning"
                    class="mb-2"
                    readonly
                  />
                  <!-- @input="test(userr.rangingType)" -->
                  <!-- @change="test(userr.rangingType)" -->
                </b-form>
              </td>
              <td style="padding: 9px; width: 25%;">
                {{ userr.notes }}
              </td>
              <td style="padding: 9px">
                {{ userr.executorName }}
              </td>
              <td style="padding: 9px">
                <p
                  v-b-modal.edit-employee-review
                  class="p-0 m-0 ml-50"
                  @click="edit(userr.feedbackId), editObj(userr)"
                >
                  <b-icon-pencil />
                </p>
              </td>

            </tr>
            <div style="margin: 10px;">
              <b-button
                v-if="selectedId != null"
                class="viewAllBtn"
                @click="showAll"
              >
                {{ $t("ViewAll") }}
              </b-button>
            </div>
          </tbody>
        </table>
      </div>
    </div>
    <AddEmployeeReview
      :user="user"
      :year="year"
      :used-months="existingArray"
      @submit="addReview"
    />
    <EditEmployeeReview
      :user="user"
      :feedback-id="feedbackId"
      :obj="editObject"
      @edit="editFeedback"
    />
    <!-- @pushFormUser="AddEditVacation" -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import AddEmployeeReview from './AddReviewModal.vue'
import EditEmployeeReview from './EditReviewModal.vue'


export default {
  components: {
    // LeaveRequestEmployeeModal,
    AddEmployeeReview,
    EditEmployeeReview,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: Object,
  },
  data() {
    return {
      userObj: {},
      showOtherComponent: false,
      isactive: true,
      searchUser: '',
      noSearch: false,
      existingArray: [],
      editObject: null,
      year: new Date().getFullYear(),
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      feedbackId: null,
      rate: 0,
      editUser: {
        userId: '',
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        street: '',
        country: '',
        email: '',
        role: '',
        resetPassword: false,
      },
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      employeeId: null,
    };
  },
  computed: {
    ...mapGetters([
      'getUsers',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForInActiveUsers',
      'getFeedback',
      'getLanguage',
    ]),
    filtered() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.getFeedback.sort((a, b) => { return b.month - a.month })
    },
  },
  watch: {
    year(value) {
      this.loadFeedback({ userId: this.user.userId, year: value })
    },
    getFeedback(value) {
      const array = value.map((item) => { return item.month })

      // console.log(array)
      this.existingArray = array
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'getUsersPagination',
      'getInactiveUsersPagination',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'filterInactiveUsersByName',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'loadVacationRequests',
      'assignEmployeeToStoree',
      'leaveRequest',
      'remainingDaysOfEmployee',
      'filterActiveUsersByName',
      'add_feedback',
      'loadFeedback',
      'edit_feedback',
      'loadEmployeeFeedbackPagination',
    ]),

    test(value) {
      // console.log(value)
      if (value === 'Bad') {
        this.rate = 1;
      } else if (value === 'Good') {
        this.rate = 2; // or 3, based on your logic
      } else if (value === 'Better') {
        this.rate = 4;
      } else if (value === 'Best') {
        this.rate = 5;
      }
      return ''; // You can return any value you want here
    },
    edit(value) {
      this.feedbackId = value
    },
    editObj(value) {
      this.editObject = value
    },
    matProps(users) {
      // this.$emit('click', users);
      this.classNameCh = users.userId;
      // this.$emit('show-the-component');
    },
    saveEmployeeId(id) {
      this.employeeId = id
    },
    async assignEmployeeToFiliale(obj) {
      await this.assignEmployeeToStoree({
        object: obj,
        successCallback: () => {
          this.getUsersPagination({
            status: this.status,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    addReview(value) {
      this.add_feedback({
        object: value,
        successCallback: () => {
          this.loadEmployeeFeedbackPagination({
            firstName: null,
            lastName: null,
            pageNumber: 1,
            pageSize: 10,
          })
          this.loadFeedback({ userId: this.user.userId, year: this.year })
        },
      })
    },
    editFeedback(value) {
      this.edit_feedback({
        feedbackId: this.feedbackId,
        object: value,
        successCallback: () => {
          this.loadEmployeeFeedbackPagination({
            firstName: null,
            lastName: null,
            pageNumber: 1,
            pageSize: 10,
          })
          this.loadFeedback({ userId: this.user.userId, year: moment().year() })
        },
      })
    },
    showAll() {
      // set the selected id to null to show all rows
      this.selectedId = null;
      this.$emit('show-other-component');
    },
    toggleBtn(truee) {
      this.sync = truee;
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    saveUser(value) {
      this.userObj = value
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
    incrementYear() {
      this.year++;
    },
    decrementYear() {
      this.year--;
    },
    monthToString(value) {
      if (value == 1) {
        return 'Janar'
      } if (value == 2) {
        return 'February'
      } if (value == 3) {
        return 'March'
      } if (value == 4) {
        return 'April'
      } if (value == 5) {
        return 'May'
      } if (value == 6) {
        return 'June'
      } if (value == 7) {
        return 'July'
      } if (value == 8) {
        return 'August'
      } if (value == 9) {
        return 'September'
      } if (value == 10) {
        return 'Octomber'
      } if (value == 11) {
        return 'November'
      } if (value == 12) {
        return 'December'
      }
      return ''
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  width: 195px;
}

.viewAllBtn{
  background: linear-gradient(90deg, rgba(38,46,108,1) 0%, rgba(155,42,92,1) 100%, rgba(255,39,79,1) 100%);
}
.addEmployeeButton {
  width: 210px;
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}
span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
.year {
width: 15%;
display: flex;
justify-content: center;
align-items: center;
  button{
  width: 35px !important;
  height: 35px !important;
  display: flex;
  border-radius: 5px !important;
  justify-content: center;
  align-items: center;
  margin: 5px;
  }
    h5{
  border:#b4b4b4 2px solid;
  border-radius: 6px;
  background-color: whitesmoke;
  width: 40%;
  height: 36px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
    }
}
</style>
